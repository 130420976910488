import React, { Component } from 'react';
import Uppy from '@uppy/core'
//import Webcam from '@uppy/webcam'
import DashboardModal from '@uppy/react/lib/DashboardModal'
// import Dashboard from '@uppy/react/lib/Dashboard'
import ImageEditor from '@uppy/image-editor'
import XHRUpload  from '@uppy/xhr-upload'
//import ProgressBar  from '@uppy/progress-bar'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'
export default class DragAndDropFileUploader extends React.Component {
  constructor (props) {
    super(props)
    //   this.state = { open: this.props.open}
      this.handleModalClick = this.handleModalClick.bind(this);
      this.init()
    }
    init = ()=>{  
      this.uppy = Uppy({
        id: 'uppy2',
        autoProceed: false,
        debug: true,
        restrictions: {
          maxFileSize: 10000000,
          maxNumberOfFiles: 1,
          minNumberOfFiles: 1,
          allowedFileTypes: ['audio/*']
        },
        // headers: {
        //   'authorization': `Bearer ${window.getCurrentUserToken()}`
        // }
      }).use(ImageEditor, {
        target: Uppy.Dashboard,
        }).use(XHRUpload, {
          endpoint: `/api/projects/${this.props.projectId}/details/uploadAudio`,
          formData: true,
          // fieldName: 'files[]'
          fieldName: 'uploadedFile',
          headers: { 
            'authorization': localStorage.getItem('accessToken') ? `Bearer ${localStorage.getItem('accessToken')}` : null 
          }
        })
        //.use(GoogleDrive, { companionUrl: 'https://companion.uppy.io' })
        this.uppy.on('upload-success', (file, response) => {
          console.log('uppy resp', response.status, response.body)
          if(this.props.callback) {
            this.props.callback()
          }
        })
        this.uppy.on('complete',  ()=> {
          this.uppy.reset()
          this.props.setOpen(false)
          // this.uppy.close()
        })
    }
  componentWillUnmount () {
    this.uppy.close();
  }
  handleModalClick () {
  this.props.setOpen(false)
  }
  render () {
    return (
        <div>
          <DashboardModal
            uppy={this.uppy}
            open={this.props.isOpen}
            target={document.body}
            onRequestClose={() => this.props.setOpen(false)}
            plugins={['ImageEditor']}
      trigger='#dashboardmodal'
      metaFields={[
        { id: 'name', name: 'Name', placeholder: 'File name' },
      ]}
          />
        </div>
    )
  }
}













