import React from 'react';
import { useEffect,useState } from 'react'
import { useHistory } from "react-router-dom";

import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import appConfig from "../../../config/development.js"

import { Location } from 'grommet-icons';



function Maps(props) {
    
  const history = useHistory();

    // const [ currentLat,setCurrentLat ] = useState(0);
    // const [ currentLng,setCurrentLng ] = useState(0);
    // const [ isMarkerShown,setMakerShown ] = useState(false);


    const mapStyle = [
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [{ color: '#ced3c5' }]
      },
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      ]
     
     const _mapLoaded = (mapProps, map) => {
        map.setOptions({
           styles: mapStyle
        })
     }


    useEffect(  ()=>{ 
      } , [ ] )
  

  return(

    <Map
    google={props.google}
    zoom={15}
    containerStyle={{
      position: 'absolute',
      width: props.width,
      height: props.height
    }}
    initialCenter={
      {
        lat: props.homecoordinates[0],
        lng: props.homecoordinates[1]
      }
    }
    // mapId='781166ba52d3edf6'
    fullscreenControl={false}
    mapTypeControl={false}
    streetViewControl={false}
    onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
    >
    { props.items.map(
                e => (
                  <Marker 
                    key={e._id}
                    position= {{ 
                      lat: e.geolocation.coordinates[0],
                      lng: e.geolocation.coordinates[1]
                    }}
                    icon={{
                      url: "https://storage.googleapis.com/cdn.kunstlink.com/general/location.svg",
                      scaledSize: new google.maps.Size(32,32)
                    }}
                    animation= {google.maps.Animation.DROP}
                    map={props}
                    title={e.title}
                    name={e.title}
                    onClick={() => {history.push(`/item/${e._id}`)}}
                    />                  
                  )
                ) 
      }  
    

    </Map>

  )
}

export default GoogleApiWrapper({
  apiKey: appConfig.maps.apikey
})(Maps);
