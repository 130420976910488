export default (hostname)=>{
    // Carry this to a  sepreate config folder
        switch(hostname){
            case 'localhost':
            return {
                hostname:hostname,
                env: 'development',
                apiHost:'localhost:3000'
            }
            case 'merchant.popperpay.com':
            return {
                hostname:hostname,
                env: 'production',
                apiHost:'service.popperpay.com'
            }
            case 'merchant-demo.popperpay.com':
            return {
                hostname:hostname,
                env: 'production',
                apiHost:'service-demo.popperpay.com',
            }
            case 'popper-web.eu.ngrok.io':
            return {
                hostname:hostname,
                env: 'development',
                apiHost:'popper.eu.ngrok.io'
            }
            default:
            return {
                hostname:hostname,
                env: 'development',
                apiHost:'popper.eu.ngrok.io'
            }
        }
    }