import React from 'react';
import { useEffect, useState, useRef } from 'react'
import { useParams, useHistory } from "react-router-dom";
import Home from '../../../pages/enduser/home';
import useAPI from "../../../helpers/useAPI";


function ProjectHome() {
  console.log('project home entered')
  const { shortname } = useParams();
    const [ project,setProject ] = useState(null);

  useEffect(  ()=>{ 
    console.log('project home use effect')
    /*will run once in the beginning*/
    async function fetchData() {
        // const res = await fetch(`/api/projects/shortname/${shortname}`)
        const res = await useAPI(`/api/projects/shortname/${shortname}`)
        const response = await res.json()
        console.log( response.data )
        
        setProject( response.data )
    }

    fetchData()
  },[]  )


  if ( !project ) return null

  return (
    <Home project={project} />
  );
}

export default ProjectHome;
