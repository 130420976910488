import * as types from '../actionTypes/appState';
 
const initialState = {
	hostname:'',
	env:'',
	apiHost:'',
	isSignedIn:false,
	userId:null,
	userType: null,
	projectId:null,
}

// const devInitialState = {
// 	isSignedIn:true,
// 	userId:null,
// 	userType: 'ADMIN',
// 	isSideBar: true,
// 	businessId:null,
// 	businessName:null,
// }

export default function appState(state = initialState, action) {
	switch (action.type) {
		case types.SET_SIGNED_IN:
			return Object.assign({}, state, {isSignedIn: action.isSignedIn});
		case types.SET_USER:
			console.log('setting user')
			return Object.assign({}, state, {userId: action.userId, userType: action.userType});
		case types.SET_PROJECT:
			return Object.assign({}, state, {projectId: action.projectId});
	  	default:
	   	return state;
	}
}
  