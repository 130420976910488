import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import React, { useRef } from 'react';
import QRCode from "qrcode.react";
import logo from './logo.svg';
import nfc from './nfc.svg';
import { Box, Button, 	Card,
	CardBody,
	CardFooter,  
 } from 'grommet';
import { DocumentPdf } from 'grommet-icons';


const ComponentToPrint = React.forwardRef((props, ref) => (
    <div 
    ref={ref}
    style={{
      width: props.width,
      height: props.height,
      position: "relative"
  }}>
  <QRCode 
      value={"http://kunstlink.com/item/"+props.link}
      size= {props.width}
      fgColor= '#000000'
      bgColor= '#ffffff'
      renderAs= {'svg'}
      imageSettings={
          {
          excavate: true,
          height: props.width / 10 ,
          width: props.width / 10 ,
          }
      }
      />
  <img src={logo} style={{
          position: "absolute",
          transform: "translate(-50%,-50%)",
          top: "50%",
          left: "50%",
          height: props.width / 10,
          width: props.width / 10
      }} />
  <img src={nfc} style={{
          position: "absolute",
          transform: "translate(-20%,10%)",
          top: "95%",
          left: "10%",
          height: props.height / 5, 
          width: props.width 
      }} />
      </div>
));

const QRgenerateButton = (props) => {
  const componentRef = useRef();

  return (
    <Box width="small">
      <Card pad="xxsmall" background="white" width="small">
      <CardBody>
      <ComponentToPrint link={props.link} width={props.width} height={props.height} ref={componentRef} />
      </CardBody>
        <CardFooter>    
          <Box pad="small">
          <Button
							label="QR Code"
							icon={<DocumentPdf /> }
              onClick={() => exportComponentAsPDF(componentRef, {fileName: `QR_${props.link}.pdf`, pdfOptions: {w:props.width, h:props.height, unit: 'pt', orientation:'p', format: [props.width,props.height]} } )}
              />
          </Box>
        </CardFooter>
      </Card>
      </Box>
  );
};

export default QRgenerateButton;