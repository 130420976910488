import React from 'react';
import { useEffect, useState } from 'react'
import { useParams, useHistory } from "react-router-dom";
import { 
  Anchor,
  Box, 
  Button, 
  Grommet,
  Layer,
  Table,
	TableRow,
	TableBody,
	TableCell,
	TableHeader,
  Text,
  TextArea,
  TextInput,
  Video,
 } from 'grommet';
import { AddCircle, View, Edit, Cycle, Close, Trash, Upload, Menu, Notes, User, Sync } from 'grommet-icons';

import passwordGenerator from "generate-password";
import projecttheme from "../../../helpers/projecttheme";
import useAPI from "../../../helpers/useAPI";




function AdminHome() {
  // const { id } = useParams();
  const [ item,setItem ] = useState(null);
  const [ projects,setProjects ] = useState(null);
  const [ users,setUsers ] = useState(null);
  const [ chosenProject,setChosenProject ] = useState(null);
  const [ isUpdating,setUpdating ] = useState(false);
  const [value, setValue] = useState('');
  const [customtheme,setTheme] = useState({});
  const [ emailBox, setEmailBox ] = useState(false);
  const [ generatedPassword, setGeneratedPassword ] = useState('create a new password, cannot be undone!');
  const [ activeUser, setActiveUser ] = useState(null);
  
  const getPassword = () => {
    const pwd = passwordGenerator.generate({
      length: 12,
      lowercase: true,
      uppercase: true,
      numbers: true,
      symbols: true
    });
    setGeneratedPassword(pwd);
  }

    
  const history = useHistory();

  async function fetchProjects() {
    // const res = await fetch(`/api/projects`)
    const res = await useAPI(`/api/projects`)
    const response = await res.json()
    console.log(response.data)
    setProjects( response.data )

  }

  async function fetchUsers() {
    const res = await useAPI(`/api/users`)
    const response = await res.json()
    console.log(response.data)
    setUsers( response.data )

  }

  useEffect(  ()=>{ 
    console.log(' admin home ')
  fetchProjects() 
  fetchUsers()
  setTheme( projecttheme(null) )

  } , [ ] )
  
  
	const addProject = async ()=>{
    const res = await useAPI(`/api/projects`, 'PUT', JSON.stringify())
		const response = await res.json()
		fetchProjects()
	}

  const changeField = (field, value) =>{
    const newProj = Object.assign({}, chosenProject)
    newProj[field] = value
    setChosenProject(newProj)
}

  const updateNewPassword = async (userId, password)=>{
    setUpdating(true)
    const body = {"password":`${password}` }
    const res = await useAPI(`/api/users/${userId}/update/password`, 'POST', JSON.stringify(body))
    const response = await res.json()
    // setItem( response.data )
    if ( response ) return 	setUpdating(false)
   }
  
 const updateProject = async (project)=>{
	setUpdating(true)
  const res = await useAPI(`/api/projects/${project._id}`, 'POST', JSON.stringify(project))
  const response = await res.json()
	// setItem( response.data )
  if ( response ) return 	setUpdating(false)
 }
 
  if ( !projects) return null
  return (
    <Grommet theme={customtheme} full >
    <Box pad="medium" gap="large">
      
      <Box 
        background="dark-1" 
        height='xssmall'
        pad="small" 
        gap="xxsmall"
        direction="row" 
        align="start"
        justify="between"
        >
          <Text weight={400}>
              Admin page
            </Text>
      </Box>

      <Box direction="row-reverse">
      <Button
          icon={<AddCircle />}
          hoverIndicator
          disabled={isUpdating}
          label="Add a new project"
          onClick={()=> addProject() }
        />

      <Button
          icon={<Notes />}
          hoverIndicator
          disabled={isUpdating}
          label="Edit legal terms"
          onClick={
            () => history.push(`/admin/legalterms`)
          }
        />

        
      </Box>

        
        <Box direction="column" gap="medium">
        {projects && projects.map((p) => {
          return(
            <Box key={p._id} direction="row" gap="small" justify="between">
              <Button
              color="dark-1"
                icon={<Edit color="black" />}
                hoverIndicator = {true}
                label={p.name}
                onClick={ 
                  () => history.push(`/admin/project/${p._id}/edit`)
                }
                /> 

                <Button
                color="dark-1"
                icon={<View color="black" />}
                hoverIndicator
                label="Open the project launch page"
                onClick={
                  ()=> history.push(`/p/${p.shortName}`) 
                }
              />
            </Box>
      
          )
          }
        )
        }
        </Box>


    <Box
      background='light-2'
      direction='column'
      width='large'
      >
      <Table>
      <TableHeader>
        <TableRow key="header">
          <TableCell>Role</TableCell>
          <TableCell>Project</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Change password</TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
          {users && users.map((user) => {	
            return(
            <TableRow key={user._id}>
              <TableCell>{user.type}</TableCell>
              <TableCell>{ user.type==='PROJECT' ? user.project.name : ''}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell><Button
                    icon={<Cycle />}
                    onClick={() => {
                      setEmailBox(!emailBox);
                      setActiveUser(user)
                    }
                       }
                  />
              </TableCell>
            </TableRow>
            )}
          )}
      </TableBody>
      </Table>

      {emailBox && (
                  <Layer
                    responsive={false}
                    position="end"
                    onClickOutside={() => { setEmailBox(!emailBox)} }
                    onEsc={() => { setEmailBox(!emailBox)} }
                    >
                    <Box
                      round="medium"
                      elevation="medium"
                      pad={{ vertical: 'xsmall', horizontal: 'xsmall' }}
                      background="light-1"
                      >
                      <Button alignSelf="end" icon={<Close />} onClick={ () => {setEmailBox(!emailBox)} } />

                      <Box
                        width="small"
                        direction="column"
                        justify="between"
                        >
                            <User />
                            <Text>{activeUser.email}</Text>
                      </Box>

                      <Box
                        width="medium"
                        direction="row"
                        margin="xxsmall"
                        align="center"
                        round="small"
                        border
                      >

                      <TextInput 
                        plain
                        name="password" 
                        type='text' 
                        onChange={(e) => setGeneratedPassword(e.target.value) } 
                        value={generatedPassword}
                      />
                      <Button
                        icon={<Cycle size="medium" /> }
                        onClick={() => getPassword()} 
                      />
                      </Box>

                      <Text>
                      Update the new password in the database, do not forget to copy this password and let the user know this new password (no automatized email is set).
                      </Text>
                      <Button
                        icon={<Sync size="medium" /> }
                        primary
                        disabled={isUpdating}
                        label="Update user details"              
                        onClick={() => updateNewPassword(activeUser._id, generatedPassword)} 
                      />
                      </Box>
                  </Layer>
                  )}


    </Box>


      </Box>
  </Grommet>
  );
}

export default AdminHome;