import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import projecttheme from '../../../helpers/projecttheme';
import logo from '../../../helpers/logo.svg';
import terms from "../../../helpers/terms";


import { 
  Box, 
  Header,
  Heading, 
  Grommet,
  Image,
  Layer,
  Text,
 } from 'grommet';
import { Location, CircleInformation, FormClose, Grommet as GrommetIcon, Menu as MenuIcon } from 'grommet-icons';



function KlHome( {project} ) {
  const history = useHistory();
  const [customtheme,setTheme] = useState({});


  useEffect(  ()=>{ 
      setTheme( projecttheme(null) )
  } , [ ] )

  return (
    <Grommet theme={customtheme} full >
    <Layer full position="center">
    <Box fill="horizontal" >

      <Box 
        background="light-1" 
        as="header"
        width="medium"
        direction="column" 
        elevation='medium'
        alignSelf="start"
        >
          <Heading level='1' margin='none'>Kunstlink</Heading>
          <Heading level='3' margin='none'>urban documentation network</Heading>
            <Image src={logo} alt="kunstlink" />
      </Box>

      <Box flex overflow="auto" pad="medium">

      <Heading level='3' margin='none'>Terms, Service, and Copyright</Heading>
       {terms()}
      </Box>

      </Box>
      </Layer>
    </Grommet>
   );
}

export default KlHome;
