// import React, { useState, useEffect } from 'react';
// import store from '../state/store';
// import {setSignedIn}  from '../state/actions/appState';
// const {appState} =  store.getState()

// let srv = appState.apiHost;
function useAPI(endpoint, method = 'GET', body=null ){
    // const url = https://${srv}/${endpoint};
    const headers = {
        'Content-Type': 'application/json'
    }

    if ( localStorage.getItem('accessToken') ) {
        headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`
    }
        
    const url = endpoint;
    const options = {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: headers,
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: body // body data type must match "Content-Type" header
    }
    return fetch(url, options)
}

export default useAPI;