import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import Maps from "../../../components/maps";
import projecttheme from '../../../helpers/projecttheme';
import useAPI from "../../../helpers/useAPI";


import { 
  Anchor,
  Box, 
  Button, 
  DropButton,
  Footer,
  Header,
  Heading,
  Image, 
  Grommet,
  Grid,
  Layer,
  Main,
  Menu,
  Nav,
  Paragraph,
  ResponsiveContext,
  Sidebar,
  Text,
 } from 'grommet';
import { Location, CircleInformation, FormClose, Grommet as GrommetIcon, Menu as MenuIcon, StatusGood } from 'grommet-icons';



function Home( {project} ) {
  const history = useHistory();

  const [open, setOpen] = useState();
  const [customtheme,setTheme] = useState({});
  const [items,setItems] = useState(null);
  const [assets,setAssets] = useState(null); 
  const [coordinates,setCoordinates] = useState();
  const [askCookieInfo, setAskCookieInfo] = useState(false);

  const sortedEntries = [].concat(items)
  .sort( (a,b) => a.title > b.title ? 1 : -1 );



  useEffect(  ()=>{ 
    setTheme( projecttheme() )

    /*will run once in the beginning*/
    async function fetchData() {
      // const res = await fetch(`/api/items/p/${project._id}`)
      const res = await useAPI(`/api/projects/${project._id}/items`)
      const response = await res.json()
      // console.log('inside home, project id =',project._id)
      // console.log( response )
      setItems( response.data )

      

      const res1 = await useAPI(`/api/projects/${project._id}`)
      const response1 = await res1.json()

      document.title = response1.data.name
    
      setTheme( projecttheme(response1.data.projectDetails.brandColor) )

      setAssets(response1.data.projectDetails.assets)

      setCoordinates(response1.data.projectDetails.geolocation.coordinates)

      }
      fetchData() 

      setTimeout(() => {
        // console.log('time out for cookie');
        setAskCookieInfo(!askCookieInfo);
        // console.log(askCookieInfo);
      }, 2000);
  
  } , [ ] )

  if ( !items || !coordinates) return null

  return (
    <Grommet theme={customtheme} full >
    <ResponsiveContext.Consumer>
    {size => (      
      
      <Box className="home" pad="small" gap="small" animation={{type:"slideLeft" , size:"xlarge"}}>

        <Header
        className="home-header"
          background="brand" 
          align="center"
          elevation="small"
          justify="between"
          pad="small" 
          gap="small"
        >
          <Heading level='3' margin='none'>{project.name}</Heading>

        </Header>

        <Box className="home-content" overflow="scroll" gap="small">

            <Box 
              background="brand" 
              height="20%"
              overflow="auto"
              >
                { sortedEntries.map(
              e => (
                    <Button
                      key={e._id}
                      icon={<Location color="gray" />}
                      hoverIndicator
                      label={e.title}
                      onClick={() => history.push(`/item/${e._id}`)}
                      gap="xxsmall"
                      alignSelf="start"
                      plain
                    />
                  )
                ) }    
            </Box>

            <Box>
              <Maps width="97%" height={size==="small" ? "50%" : "50%"} homecoordinates={coordinates} items={items} />
            </Box>
        </Box>

        {askCookieInfo && (
                  <Layer
                    responsive={false}
                    position="bottom"
                    modal={false}
                    margin={{ vertical: 'medium', horizontal: 'small' }}
                  >
                    <Box
                      width="medium"
                      align="center"
                      direction="row"
                      gap="small"
                      justify="between"
                      elevation="high"
                      pad={{ vertical: 'xsmall', horizontal: 'small' }}
                      background="status-ok"
                    >
                      <Box align="center" direction="row" gap="xsmall">
                        <Text color="white">
                  We use essential cookies for the functionality.
                        </Text>
                        <Button icon={<StatusGood color="white"/>} onClick={ () => setAskCookieInfo(!askCookieInfo) } />
                      </Box>
                    </Box>
                  </Layer>
                  )}   

        <Footer justify='between' >
            <Grid
                      columns={{
                        count: 2,                
                        size: 'auto',
                      }}
                        gap="small"
                        >
                              {assets && assets.map((img,i) => {
                                if (img.type === 'IMAGE') {
                                  return ( 
                                      <Image key={i} src={img.src} width="100%" height="100pt" fit="contain" />
                                    )
                                  }
                                })
                              }
                        </Grid>
                        
            </Footer>
            <Footer justify='between' >      
            <Box 
          fill="horizontal" 
          height = "3%"
          background="light-3"
          onClick={ () => history.push("/") }
          align="center"
          >
              <Text weight={100} size="xsmall">Imprint ♩ Terms ♩ Copyright</Text>
          </Box>
          </Footer>

      


      </Box>
      
    )}
    </ResponsiveContext.Consumer>
    </Grommet>
   );
}

export default Home;
