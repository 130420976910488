import React, { useRef } from 'react';
import QRCode from "qrcode.react";
import logo from './logo.svg';
import nfc from './nfc.svg';


const QRcomponent = React.forwardRef((props, ref) => (
    <div 
    ref={ref}
    style={{
      width: props.width,
      height: props.height,
      position: "relative"
  }}>
  <QRCode 
      value={"https://www.kunstlink.com/item/"+props.link}
      size= {props.width}
      fgColor= '#000000'
      bgColor= '#ffffff'
      renderAs= {'svg'}
      imageSettings={
          {
          excavate: true,
          height: props.width / 8 ,
          width: props.width / 8 ,
          }
      }
      />
  <img src={logo} style={{
          position: "absolute",
          transform: "translate(-50%,-50%)",
          top: "32%",
          left: "50%",
          height: props.width / 8,
          width: props.width / 8
      }} />
  <img src={nfc} style={{
          position: "absolute",
          transform: "translate(-50%,-50%)",
          top: "82%",
          left: "50%",
          width: props.width 
      }} />
      </div>
));

export default QRcomponent;