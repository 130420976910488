import React from 'react';
import { push } from 'connected-react-router'
import { useEffect, useState } from 'react'
import { useParams, useHistory, withRouter } from "react-router-dom";
import jwtDecode from 'jwt-decode';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'; 
import store from '../../../state/store';
import * as _actions from '../../../state/actions/appState'; 

import {
    Box,
    Button,
    Form,
    FormField,
    Grommet,
    Heading,
    Text,
    TextInput,
  } from 'grommet';
  
import { Hide, View, DocumentPdf, Edit, NewWindow } from 'grommet-icons';
  
import copy from "copy-to-clipboard";

import kunstlinktheme from '../../../helpers/kunstlinktheme';
import useAPI from "../../../helpers/useAPI"




import { resetWarningCache } from 'prop-types';

function Login({actions}) {
    const history = useHistory(); 
    const [value, setValue] = useState({ email: '', password: '' });
    const [customtheme,setTheme] = useState({});
    const [revealPassword, setRevealPassword] = useState(false);
    const message = value.name=='' && value.email==''
      ? 'Fill in the form'
      : undefined;	
	
    useEffect(  ()=>{ 
    /*will run once in the beginning*/
        setTheme( kunstlinktheme(null) )
    } , [ ] )

    const submit = async (email, password)=>{
    // const options = {
    //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //     mode: 'cors', // no-cors, *cors, same-origin
    //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //     credentials: 'same-origin', // include, *same-origin, omit
    //     headers: { 'Content-Type': 'application/json'}, // 'Authorization': 'Bearer'+accesstoken (concat)  
    //     body: 
    // }
    const res = await useAPI(`/api/login/`, 'POST', JSON.stringify({email, password}) )
    const response = await res.json()
    console.log(response)
    localStorage.setItem('accessToken',response.data.accessToken);
    localStorage.setItem('refreshToken',response.data.refreshToken);
    const token = jwtDecode(response.data.accessToken);
    console.log('obtained token: ',token)

    actions.setUser(token.id, token.type);
    actions.setSignedIn(true);


    if(token.type === 'PROJECT'){ 
      console.log('routing to projects admin page')
      actions.setProject(token.project)
      history.push(`/projects/${token.project}`) 
    }
    else if(token.type === 'ADMIN'){ 
      console.log('routing to the admin page')
      store.dispatch(push('/admin'))
      //history.push('/admin') 
    }
    else{
      console.log('routing back to login')
      history.push('/login') 
    }
    

    }


  return (
	<div> 
	<Grommet theme={customtheme} full>
	<Box
        tag='header'
        direction='row'
        align='center'
        justify='between'
        background='brand'
        pad={{ left: 'medium', right: 'small', vertical: 'small' }}
        elevation='medium'
        style={{ zIndex: '1' }}
        >
          <Heading level='3' margin='none'>Kunstlink</Heading>
        </Box>

    <Box fill align="center" justify="center">
        <Box width="medium">
            <Form
                value={value}
                onChange={nextValue => setValue(nextValue)}
                onSubmit={({ value: nextValue }) => console.log(nextValue)}
            >

                <FormField label="Email" name="email" required>
                <Box
                  width="medium"
                  direction="row"
                  margin="xxsmall"
                  align="center"
                  round="small"
                  border
                >
                <TextInput name="email" type="email" />
                </Box>
                </FormField>

                <FormField label="Password" name="password" required>
                <Box
                  width="medium"
                  direction="row"
                  margin="xxsmall"
                  align="center"
                  round="small"
                  border
                >
                <TextInput plain name="password" type={revealPassword ? 'text' : 'password'} />
                <Button
                  icon={revealPassword ? <View size="medium" /> : <Hide size="medium" />}
                  onClick={() => setRevealPassword(!revealPassword)} 
                />
                </Box>
                </FormField>

                {message && (
                <Box pad={{ horizontal: 'small' }}>
                    <Text color="status-error">{message}</Text>
                </Box>
                )}


                <Box direction="row" justify="between" margin={{ top: 'medium' }}>
                <Button type="reset" label="Reset" onClick={()=>setValue( {email:' ' , password:' '} ) }  />
                <Button type="submit" label="Submit" primary onClick={()=> submit(value.email, value.password) } />
                </Box>

            </Form>
        </Box>
    </Box>
	</Grommet>     
	</div>
  );
}


function mapDispatchToProps(dispatch) {
  return {
  actions: bindActionCreators(_actions, dispatch)
  };
  }

function mapStateToProps(state) {
  return {
  appState: state.appState
  };
  } 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)); 