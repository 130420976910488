import { deepMerge } from "grommet/utils";
import { grommet } from "grommet/themes";

export default (brandColor) =>{

    const theme = deepMerge(grommet, {
        global: {
          colors: {
            brand: brandColor ? brandColor : '#FFFFFF',
            'accent-1': '#6FFFB0',      
            'accent-2': '#FD6FFF',      
            'accent-3': '#81FCED',      
            'accent-4': '#FFCA58',      
            'neutral-1': '#00873D',      
            'neutral-2': '#3D138D',
            'neutral-3': '#00739D',
            'neutral-4': '#A2423D',
            },
          font: {
            family: 'Roboto',
            size: '18px',
            height: '20px',
          },
          breakpoints: {
            small: {
              value: 480,
            },
            medium: {
              value: 800,
            },
            large: {
              value: 1000,
            }
          }
        }
      })
      
        return theme;
    }

    // https://v2.grommet.io/color