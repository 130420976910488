const cdnkey = require('../apiserver/cert/cdnkey.json')

module.exports = {
	admin: {
	  users: [{
		email: 'ata@popperpay.com',
		pass: 'abcd'
	  }]
	},
	auth:{
		cookie:{
			currentToken:'ABC',
			previousToken:'ABC',
			expiration:'100 days',
			refreshExpiration:'100 days'
		}
	},
	db:{
		mongo:{
			host: 'cluster0.ijl2l.mongodb.net/kunstlink-dev?retryWrites=true&w=majority',
			username: 'adminBillerbeckMuseum',
			password: 'cZzTZGgxRHUYuQGi',
			protocol: 'mongodb+srv'
		},
		redis:{
		  host: 'localhost',
		  port: '6379'
		}
	  },
	npm:{
		port: '3000',
		uiport: '3001'
	},
	maps:{
		apikey: 'AIzaSyARTQFIfFfpWYlEIEzY-6QsmnhCb9my8HM',
		homelatitude: 52.5067614,
		homelongitude: 13.2846515
	},
	ax:{
		measurementID: "G-JQDNSXLBKN"
	},
	storage:{
		credentials: cdnkey,
		bucket: 'cdn.kunstlink.com'
	},
	host:'https://localhost:3000'
}

