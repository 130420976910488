/* eslint-disable import/no-named-as-default */
import {BrowserRouter,Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import { hot } from "react-hot-loader";

import Login from "../../pages/common/login";
import Item from "../../pages/enduser/item";
import KlHome from "../../pages/common/klhome";
import ProjectHome from "../../pages/common/projecthome";


import ProjectAdminHome from "../../pages/project/home";
import ItemEditor from "../../pages/project/itemEditor";

// import Analytics from 'react-router-ga';

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends React.Component {
  render() {
    const activeStyle = { color: 'blue' };
    return (
      <div>
        <BrowserRouter>
        {/* <Analytics id="G-JQDNSXLBKN" debug> */}
        <Switch>
          <Route exact path="/" component={KlHome} />
          <Route exact path="/p/:shortname" component={ProjectHome} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/item/:id" component={Item} />
\
          <Route path="/item/:id/edit" component={ItemEditor} />
          <Route path="/projects/:projectId" component={ProjectAdminHome} />
        </Switch>
        {/* </Analytics> */}
        </BrowserRouter>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);
