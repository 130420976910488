import React from 'react';
import { useEffect, useState } from 'react'
import { useParams, useHistory } from "react-router-dom";
import { 
  Anchor,
  Box, 
  Button, 
  Grommet,
  Layer,
  Table,
	TableRow,
	TableBody,
	TableCell,
	TableHeader,
  Text,
  TextArea,
  TextInput,
  Video,
 } from 'grommet';
import { AddCircle, View, Edit, Cycle, Close, Trash, Upload, Menu, User, Save, Sync } from 'grommet-icons';

import projecttheme from "../../../helpers/projecttheme";
import useAPI from "../../../helpers/useAPI";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



function LegalTerms() {
  // const { id } = useParams();
  const [ tabs,setTabs ] = useState(null);
  const [ tab,setTab ] = useState(null);
  const [ tabName,setTabName ] = useState(null);
  const [ tabContent,setTabContent ] = useState(null);
  const [ isUpdating,setUpdating ] = useState(false);
  const [customtheme,setTheme] = useState({});

  
    
  const history = useHistory();

  async function fetchTabs() {
    const res = await useAPI(`/api/kl`)
    const response = await res.json()
    console.log(response.data)
    setTabs( response.data )
  }


  useEffect(  ()=>{ 
  fetchTabs() 
  setTheme( projecttheme(null) )
  console.log(tabContent)

  } , [ ] )
  
  
const addTab = async ()=>{
    const res = await useAPI(`/api/kl`, 'PUT', JSON.stringify())
		const response = await res.json()
		fetchTabs()
	}

const changeName = (tab, value) =>{
    const newTab = Object.assign({}, tab)
    newTab["name"] = value
    setTab(newTab)
}

const updateTab = async (tab)=>{
	setUpdating(true)
  const newTab = Object.assign({}, tab)
  newTab["name"] = tabName
  newTab["content"] = tabContent
  const res = await useAPI(`/api/kl/${tab._id}`, 'POST', JSON.stringify(newTab))
  const response = await res.json()
  if ( response ) return 	setUpdating(false)
 }
 
  if ( !tabs) return null
  return (
    <Grommet theme={customtheme} full >
    <Box pad="medium" gap="large">
      
      <Box 
        background="brand" 
        height='xssmall'
        pad="small" 
        gap="xxsmall"
        direction="row" 
        align="start"
        justify="between"
        >
          <Text weight={400}>
              Admin page
            </Text>
      </Box>

      <Button
          icon={<AddCircle />}
          hoverIndicator
          primary
          disabled={isUpdating}
          label="Add a new tab"
          onClick={()=> addTab() }
        />
      

      <Box direction="column" gap="medium">
        {tabs && tabs.map((t) => {
          return(
            <Box key={t._id} direction="column" gap="small" justify="between">
               <TextInput  
                        onChange={(e) => setTabName(e.target.value) } 
                        value={tabName}
                      />

                <ReactQuill theme="snow" value={tabContent} onChange={setTabContent}></ReactQuill>

                <Button
          icon={<Save />}
          hoverIndicator
          primary
          disabled={isUpdating}
          label="Update"
          onClick={()=> updateTab(t) }
        />
            </Box>
      
          )
          }
        )
        }
        </Box>


  

      </Box>
  </Grommet>
  );
}

export default LegalTerms;