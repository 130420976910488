import React from 'react';
import { useEffect, useState } from 'react'
import { useParams, useHistory } from "react-router-dom";
import { 
  Anchor,
  Box, 
  Button, 
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grommet,
  Grid,
  Image,
  Paragraph,
  Form, 
  FormField,
  ResponsiveContext,
  Text,
  TextArea,
  TextInput,
  Video,
 } from 'grommet';
import { Bookmark, CloudUpload, CirclePlay, Pause, Location, Grommet as GrommetIcon, FormClose, LinkPrevious, Trash, Upload, Menu, Sync } from 'grommet-icons';
import projecttheme from "../../../helpers/projecttheme";
import UppyNoButtonImage from "../../../components/uppyImageItem";
import UppyNoButtonAudio from "../../../components/uppyAudioItem";
import ReactHowler from 'react-howler';

import useAPI from "../../../helpers/useAPI"

import Editor from "../../../components/editor";


function ItemEditor() {
    console.log(' item editor ');
    const history = useHistory();
    const { id } = useParams();
    const [ item,setItem ] = useState(null);
    const [ isUpdating,setUpdating ] = useState(false);
    const [value, setValue] = useState('');
    const [customtheme,setTheme] = useState({});
    const [uppyImageState, setUppyImageState] = useState(false);
    const [uppyAudioState, setUppyAudioState] = useState(false);
    const [ playingAudio, setPlayingAudio ] = useState(false);
    const [ audioAvailable, setAudioAvailable ] = useState(false);
    
  
    

    /*will run once in the beginning*/
    async function fetchData() {
      // const res = await fetch(`/api/items/${id}`)
      const res = await useAPI(`/api/items/${id}`)
      const response = await res.json()
      setItem( response.data )
    }

    useEffect(  ()=>{ 
    fetchData() 

  } , [ ] )
  
  const changeField = (field, value) =>{
	const newItem = Object.assign({}, item)
			newItem[field] = value
	setItem(newItem)
  }

  const changeLongitude = (value) =>{
    const newItem = Object.assign({}, item)
        newItem["geolocation"] = {"type": "Point", "coordinates": [value, item.geolocation.coordinates[1]] }
    setItem(newItem)
    }

  const changeLatitude = (value) =>{
    const newItem = Object.assign({}, item)
        newItem["geolocation"] = {"type": "Point", "coordinates": [item.geolocation.coordinates[0], value] }
    setItem(newItem)
    }
  
 const update = async (projectId, itemId)=>{
	setUpdating(true)
	// const options = {
	// 	method: 'POST', // *GET, POST, PUT, DELETE, etc.
	// 	mode: 'cors', // no-cors, *cors, same-origin
	// 	cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
	// 	credentials: 'same-origin', // include, *same-origin, omit
	// 	headers: { 'Content-Type': 'application/json'},
	// 	body: JSON.stringify(item)
	// }
	// const res = await fetch(`/api/items/${id}`, options)
  const res = await useAPI(`/api/projects/${projectId}/items/${itemId}`, 'POST', JSON.stringify(item) )
    const response = await res.json()
	setItem( response.data )
  if ( response ) return 	setUpdating(false)
 }

 const deleteAsset = async (projectId, itemId, assetId) =>{
  const res = await useAPI(`/api/projects/${projectId}/items/${itemId}/assets/${assetId}`, 'DELETE')
  const response = await res.json()
  console.log(' --- deleting asset ',response)
  fetchData()

 }
 const theme = {
  global: {
    colors: {
    brand: '#228BE6',
    },
    font: {
    family: 'Roboto',
    size: '18px',
    height: '20px',
    },
  },
  };

 
  if ( !item) return null
  return (
    <Grommet theme={theme} full >
    <Box pad="small" gap="small">
      
      <Box 
        background="brand" 
        height='xxsmall'
        pad="small" 
        gap="xxsmall"
        direction="row" 
        align="start"
        justify="between"
        >
          <Text weight={400}>
              {item.title}
            </Text>
            <Button
              icon={<LinkPrevious />}
              tip={{ dropProps: { align: { right: 'left' } }, content: 'without update, changes will be lost' }}
              onClick={ ()=>history.push(`/projects/${item.project}`) }
              alignSelf="center"
              align="center"    
            />
      </Box>

      <Box 
			direction="row" 
			align="center"
			background="light-3"
			pad = "large"
		>


		<Box width="xlarge" pad='medium' direction='column'>



      <Box align="start" pad="small" >
        <Text>Item title</Text>
        <TextInput 
          size="medium"
          icon={<Bookmark />}
          placeholder={item.title}
          onChange={(e) => changeField('title', e.target.value)} value={item.title}
          />
      </Box>

      <Box align="start" pad="small" >
        <Text>Item coordinates, longitude and latitude</Text>
        <Box>
        <TextInput 
          size="medium"
          icon={<Location />}
          placeholder={item.title}
          onChange={(e) => changeLongitude(e.target.value)} value={item.geolocation.coordinates[0]}
          />
        <TextInput 
          size="medium"
          icon={<Location />}
          placeholder={item.title}
          onChange={(e) => changeLatitude(e.target.value)} value={item.geolocation.coordinates[1]}
          />
        </Box>
      </Box>

      {/* <Box align="start" pad="small" height="large" >
        <Text>Description</Text>
        <TextArea 
          value={item.description}
          onChange={(e) => changeField('description', e.target.value)} value={item.description} fill />
      </Box> */}

      
        <Text>Description</Text>
        <Editor 
          contentDB={item.description} 
          updateHandler={ (e) => changeField('description', e) } /> 
    </Box>

      <Button
          icon={<Sync color="black" />}
          hoverIndicator
          primary
          active
          disabled={isUpdating}
          label="Update database"
          onClick={()=>update(item.project, item._id)}
          gap="large"
		      alignSelf="center"
		      align="center"
		    />

  </Box>

      <Box fill align="start" pad="medium" height="medium" >
        <Text>Audio</Text>
        {item && item.assets && item.assets.map((audio) => {
          if (audio.type === 'AUDIO') {
            return ( 

              <Box
              key = {audio._id}
              background="plain" 
              width="small"
              pad="small" 
              >
  
              <ReactHowler
                  src = {audio.src}
                  playing = {playingAudio}
                  onPlay = { console.log('howler plays') }
                  onPause = { console.log('howler is paused') }
                  />
              <Button
                icon={ playingAudio ? <Pause color='plain' size='large' /> : <CirclePlay color='plain' size='large' /> }
                hoverIndicator = {false}
                label=""
                onClick={ () => { 
                  setPlayingAudio(!playingAudio)
                }} 
                gap="xxsmall"
                />

              <Button
                primary
                icon={<Trash color="white" />}
                tip={{ dropProps: { align: { left: 'right' } }, content: 'delete this audio' }}
                hoverIndicator
                label="Delete"
                onClick={()=> deleteAsset(item.project, item._id, audio._id) }
                gap="medium"
                alignSelf="start"
              />

            </Box>
  
                    )
          }
          })}

        <Box>
        <Button
              icon={<Upload color='black'/>}
              primary
              active
              disabled={audioAvailable}
              hoverIndicator
              label="Upload audio"
              onClick={()=>{ setUppyAudioState(true)}}
              gap="xxsmall"
              alignSelf="start"
            />

        </Box>
        <UppyNoButtonAudio projectId={item.project} itemId={item._id} callback={fetchData} isOpen={uppyAudioState} setOpen={(a)=>{setUppyAudioState(a)}}/>
      </Box>


      <Box fill align="start" pad="medium" height="medium" >
        <Text>Photos</Text>
        <Grid
          columns={{
            count: 3,
            size: 'auto',
          }}
          gap="small"
          >
                {item && item.assets && item.assets.map((img) => {
                  if (img.type === 'IMAGE') {
                    return ( <Card key={img._id} pad="xsmall" background="light-3">
                              <CardBody><Image  src={img.src} /></CardBody>
                              <CardFooter>    
                                <Box pad="small">
                                <Button
                                  primary
                                  icon={<Trash color="white" />}
                                  tip={{ dropProps: { align: { left: 'right' } }, content: 'delete this picture' }}
                                  hoverIndicator
                                  label="Delete"
                                  onClick={()=> deleteAsset(item.project, item._id,img._id)}
                                  gap="medium"
                                  alignSelf="start"
                                />
                                </Box>
                              </CardFooter>
                              </Card>
                            )
                    }
                  })
                }
          </Grid>

        <Box pad="small">
        <Button
              icon={<CloudUpload color="white" />}
              primary
              hoverIndicator
              label="Upload image"
              onClick={()=>{ setUppyImageState(true)}}
              gap="xxsmall"
              alignSelf="start"
            />
        </Box>
        <UppyNoButtonImage projectId={item.project} itemId={item._id} callback={fetchData} isOpen={uppyImageState} setOpen={(a)=>{setUppyImageState(a)}}/>
      </Box>




      </Box>
  </Grommet>
  );
}

export default ItemEditor;