import * as types from '../actionTypes/appState';

// example of a thunk using the redux-thunk middleware
export function setSignedIn(isSignedIn) {
	return function (dispatch) {
    // thunks allow for pre-processing actions, calling apis, and dispatching multiple actions
    // in this case at this point we could call a service that would persist the fuel savings
		return dispatch({
			type: types.SET_SIGNED_IN,
			isSignedIn:isSignedIn
		});
  };
}

// export function setUser(userId, userType) {
// 	console.log('within the set user')
// 	return function (dispatch) {
// 		return dispatch({
// 			type: types.SET_USER,
// 			userId:userId,
// 			userType: userType
// 		});
//   };
// }


export function setUser(userId, userType) {
	console.log('within the set user')
		return {
			type: types.SET_USER,
			userId:userId,
			userType: userType
		};
}

export function setProject(projectId) {
	return function (dispatch) {
		return dispatch({
			type: types.SET_PROJECT,
			projectId:projectId
		});
  };
}
