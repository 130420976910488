import React from 'react';
import { useEffect, useState } from 'react'
import { useParams, useHistory } from "react-router-dom";
import { 
  Anchor,
  Box, 
  Button, 
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grommet,
  Grid,
  Image,
  Layer,
  Paragraph,
  Form, 
  FormField,
  ResponsiveContext,
  Text,
  TextArea,
  TextInput,
  Video,
 } from 'grommet';
import { AddCircle, Close, Document, Trash, Upload, UserAdd, LinkPrevious, Menu, Sync } from 'grommet-icons';
import projecttheme from "../../../helpers/projecttheme";
import deserialize from "../../../helpers/deserialize";

import useAPI from "../../../helpers/useAPI";


function ProjectEditor() {
  const { projectId } = useParams();
  const [ chosenProject,setChosenProject ] = useState(null);
  const [ isUpdating,setUpdating ] = useState(false);
  const [ userAdding,setUserAdding ] = useState(false);
  const [value, setValue] = useState({ email: ' ', password: ' ' });
  const [ customtheme,setTheme ] = useState({});
    

  const history = useHistory();

  /*will run once in the beginning*/
  async function fetchProject() {
    const res = await useAPI(`/api/projects/${projectId}`)
    const response = await res.json()
    console.log(response.data)
    setChosenProject( response.data )
  }


  useEffect(  ()=>{ 
  fetchProject() 
  setTheme( projecttheme(null) )
  } , [ ] )
  
  const changeField = (field, value) =>{
    	const newProj = Object.assign({}, chosenProject)
			newProj[field] = value
	    setChosenProject(newProj)
  }
  
  const updateDB = async (project, details)=>{
    setUpdating(true)
    const res1 = await useAPI(`/api/projects/${project._id}`, 'POST', JSON.stringify(project))
    const response1 = await res1.json()
      
    if (  response1 ) {
        setUpdating(false)
      }
    }

 const userAdd = async (email, pass)=>{
	setUpdating(true)
    const body = {"email":`${email}`, "password":`${pass}`, "type":"PROJECT", "projectId": `${projectId}` }
  const res = await useAPI('/api/users', 'PUT', JSON.stringify(body) )
  const response = await res.json()
  console.log(response)
  if (  response ) {
      setUpdating(false)
      setUserAdding(!userAdding)
  }
 }

 
  if ( !chosenProject ) return null
  return (
    <Grommet theme={customtheme} full >
    <Box pad="small" gap="small">

      <Box 
        background="dark-1" 
        as="header"
        height='xxsmall'
        pad="small" 
        gap="xxsmall"
        direction="row" 
        align="center"
        justify="between"
        >
            <Text weight={400}>
            Admin page, project: {chosenProject.name}
            </Text>
            <Button
              icon={<LinkPrevious />}
              onClick={ () => history.push('/admin') }
            />
      </Box>


      <Box direction="row">
        
        <Box direction="column" width="large">

            <Button
              icon={<Sync color="black" />}
              hoverIndicator
              primary
              active
              disabled={isUpdating}
              label="Update database"
              onClick={()=> updateDB(chosenProject) }
              gap="medium"
              alignSelf="start"
            />


            <Box fill align="start" pad="small" >
            <Text>Project name</Text>
            <TextInput 
              size="medium"
              onChange={(e) => changeField('name', e.target.value)} 
              value={chosenProject.name}
              />
            </Box>

            <Box fill align="start" pad="small" >
            <Text>Project short name for home link</Text>
            <TextInput 
              size="medium"
              onChange={(e) => changeField('shortName', e.target.value)} 
              value={chosenProject.shortName}
              />
            </Box>


        </Box>


        <Box direction="column" gap="medium">


            <Button
                icon={<UserAdd color="black" />}
                hoverIndicator
                primary
                active
                disabled={isUpdating}
                label="Add a user"
                onClick={ ()=>{ setUserAdding(!userAdding) } }
                gap="medium"
                alignSelf="start"
                />


                {userAdding && (
                  <Layer
                    responsive={false}
                    position="start"
                    onClickOutside={() => { setUserAdding(!userAdding) } }
                    onEsc={() => { setUserAdding(!userAdding) } }
                    >
                    <Box
                      round="medium"
                      elevation="medium"
                      pad={{ vertical: 'xsmall', horizontal: 'small' }}
                      background="light-3"
                      >
                      <Button alignSelf="end" icon={<Close />} onClick={ () => {setUserAdding(!userAdding)} } />

                      <Form
                        value={value}
                        onChange={nextValue => setValue(nextValue)}
                        onSubmit={({ value: nextValue }) => console.log(nextValue)}
                        >

                        <FormField label="Email" name="email" required>
                        <TextInput name="email" type="email" />
                        </FormField>

                        <FormField label="Password" name="password" required>
                        <TextInput name="password" type="password" />
                        </FormField>

                        <Box direction="row" justify="between" margin={{ top: 'medium' }}>
                        <Button type="reset" label="Reset" onClick={()=>{setValue( {email:' ' , password:' '} )}}  />
                        <Button type="submit" label="Submit" icon={<AddCircle />} primary onClick={()=>{userAdd(value.email, value.password)}} />
                        </Box>

                    </Form>
                    </Box>
                  </Layer>
                  )}

              <Button
                icon={<Document color="black" />}
                hoverIndicator
                primary
                active
                disabled={isUpdating}
                label="Edit as a project admin"
                onClick={ ()=> history.push((`/projects/${chosenProject._id}`) ) }
                gap="medium"
                alignSelf="start"
                />

        
        {/* {users && users.map((u) => {
          return(
              <Button
                key={u._id}
                hoverIndicator = {true}
                label={u.name}
                onClick={ () => { 
                }} 
                gap="xxsmall"
                /> 
          )
          }
        )
        } */}
          
        </Box>

      </Box>
  


      </Box>
  </Grommet>
  );
}

export default ProjectEditor;