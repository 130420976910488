export default class StateLoader {
    constructor(host){
        this.host =  host;
    }
    loadState() {
        console.log('this.host', this.host)
        try {
            let serializedState = localStorage.getItem(`${this.host}:state`);
            if (serializedState === null) {
                return this.initializeState();
            }

            return JSON.parse(serializedState);
        }
        catch (err) {
            console.log('err', err)
            return this.initializeState();
        }
    }

    saveState(state) {
        console.log('saving state', this.host, state)
        try {
            let serializedState = JSON.stringify(state);
            localStorage.setItem(`${this.host}:state`, serializedState);

        }
        catch (err) {
        }
    }

    initializeState() {
        const appState = {
            hostname:'',
            env:'',
            apiHost:'',
            isSignedIn:false,
            userId:null,
            userType: null,
            isSideBar: false,
            businessId:null,
            businessName:null,
        }
        return {appState}
    };
    
}