/* eslint-disable import/no-named-as-default */
import {BrowserRouter,Route, Switch, Redirect } from "react-router-dom";
import AdminRouter from './admin'
import ProjectRouter from './project'
import HomeRouter from './enduser'


import React from "react";
import {connect} from 'react-redux';



const Router =({appState})=> {
if(appState.isSignedIn && appState.userType === 'ADMIN'){
return(<BrowserRouter>
                <AdminRouter/>
        </BrowserRouter>)
}

if(appState.isSignedIn && appState.userType === 'PROJECT'){
return(<BrowserRouter>
                <ProjectRouter/>
        </BrowserRouter>)
}

return(<BrowserRouter>
                <HomeRouter/>
        </BrowserRouter>)
}

function mapStateToProps(state) {
return {
appState: state.appState
};
}
export default connect(mapStateToProps)(Router); 