import React from 'react';
import { useEffect, useState, useRef } from 'react'
import { useParams, useHistory } from "react-router-dom";
import { 
  Anchor,
  Box, 
  Button, 
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Carousel,
  Distribution,
  Footer,
  Heading, 
  Header,
  Image,
  Grid,
  Grommet,
  Layer,
  Paragraph,
  ResponsiveContext,
  Text,
  Video,
 } from 'grommet';
import {  CirclePlay, Close, FormClose, Home, Gallery, Location, LinkPrevious, Menu, Pause, StatusGood, CircleInformation } from 'grommet-icons';

import dompurify from 'dompurify';

// import {Howl, Howler} from 'howler';
import ReactHowler from 'react-howler';
// import StackGrid from 'react-stack-grid';
// import { CSSGrid, layout} from 'react-stonecutter';

// import Masonry from 'react-masonry-component';
// import Masonry from 'react-masonry-css'
import Masonry from "react-responsive-masonry"

import deserialize from "../../../helpers/deserialize";
import projecttheme from "../../../helpers/projecttheme";

import useAPI from "../../../helpers/useAPI";

// import GalleryGrid from "react-grid-gallery"

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as SwipeCarousel} from 'react-responsive-carousel';


function Item() {
    const { id } = useParams();
    const [ item,setItem ] = useState(null);
    const history = useHistory();

    const [ assets, setAssets ] = useState({});
    const [ imageExists, setImageExists ] = useState(false);
    const [ customtheme, setTheme ] = useState({});
    // const [ brandColor, setBrandColor ] = useState('#FFFFFF');
    
    // const [ sound, setSound ] = useState(null);
    const [ playingAudio, setPlayingAudio ] = useState(false);
    const [ photoCarousel, setPhotoCarousel ] = useState(false);
    const [ imagesList, setImagesList ] = useState([]);
    const [ infoBox, setInfoBox ] = useState(false);
    // const [ audioUrl, setAudioUrl ] = useState(null);

    const [ projectDetails,setProjectDetails ] = useState(null);
    const [ project,setProject ] = useState(null);
    const [ projectAssets,setProjectAssets ] = useState(null);

    const [askCookieInfo, setAskCookieInfo] = useState(false);

    const largeImagesList = [];
    const mediumImagesList = [];

    

  
   async function fetchData() {
          const res = await useAPI(`/api/items/${id}`)
          const response = await res.json()
          // console.log( response.data )
          
          setItem( response.data )
          setAssets( deserialize( response.data.assets ) )
          // console.log(deserialize( response.data.assets ))
          // console.log( `${item.assets}` )
          // setAudioUrl( assets[0] )
          // setAudioUrl(response.data.audio)


      
  
      
          const res1 = await useAPI(`/api/projects/${response.data.project}`)
          const response1 = await res1.json()
        
          setProject( response1.data )
          // console.log( response1.data )
  
          setProjectDetails( response1.data.projectDetails)

          document.title = response1.data.name

          setTheme( projecttheme(response1.data.projectDetails.brandColor) )

          


        
      }
  

      const fillImageLists = (assets) =>{
    
        { assets && assets.map((img,i) => {            
          if (img.type === 'IMAGE') {
            largeImagesList.push( img.sizes.length ? img.sizes[5] : img.src )
            mediumImagesList.push( img.sizes.length ? img.sizes[3] : img.src )
          } } ) 
        }
    
      }
    

  useEffect(  ()=>{ 

    setTheme( projecttheme() )

    fetchData() 

    setTimeout(() => {
      // console.log('time out for cookie');
      setAskCookieInfo(!askCookieInfo);
      // console.log(askCookieInfo);
    }, 2000);

    } , [] )


  if ( item && assets) {
    // console.log(item.assets)
    fillImageLists(item.assets)
  }

  const sanitizer = dompurify.sanitize;


  if ( !item || !assets ) return null

  return (
    <Grommet theme={customtheme} full >
    <ResponsiveContext.Consumer>
    {size => (      
      
      <Box className="item" pad="small" gap="small" animation={{type:"slideRight" , size:"xlarge"}}>
      
       
      <Header 
      className="item-header"
        background="brand" 
        height='xxsmall'
        direction="row" 
        align="center"
        justify="between"
        pad={{left:"medium"}}
        >
            <Text weight={400}>
              {/* <Location /> */}
            {item.title}
            </Text>
            <Button
              icon={<LinkPrevious />}
              onClick={ () => history.push(item.projectLink) }
            />
      </Header>

      <Box className="item-content" overflow="scroll">
        
        <Box pad="small" gap="xxsmall" direction={size==="small" ? "column" : "row"} >

        <Box pad="xxsmall" gap="large" direction={size==="small" ? "row" : "column"}>
          {item && item.assets && item.assets.map((audio) => {
            if (audio.type === 'AUDIO') {
              return ( 

                <Box
                key = {audio._id}
                background="plain" 
                >
    
                <ReactHowler
                    src = {audio.src}
                    playing = {playingAudio}
                    html5 = {true}
                    />
                <Button
                  icon={ playingAudio ? <Pause color='plain' size='large' /> : <CirclePlay color='plain' size='large' /> }
                  hoverIndicator = {false}
                  label=""
                  onClick={ () => { 
                    setPlayingAudio(!playingAudio)
                  }} 
                  gap="xxsmall"
                  />
              </Box>
    
                      )
            }
            })}

          {item.assets && item.assets.map((pix) => {
            if (pix.type === 'IMAGE') {
              setImageExists(true)
            }
          })
          }

        {imageExists && <Button
              icon={ <Gallery color='plain' size='large' /> }
              disabled={item.assets ? false : true}
              hoverIndicator = {false}
              label=""
              onClick={ () => setPhotoCarousel(!photoCarousel) } 
              gap="xxsmall"
              />
          }

                  {photoCarousel && (
                  <Layer
                    responsive={false}
                    position={size==="small" ? "start" : "center"}
                    onClickOutside={() =>  setPhotoCarousel(!photoCarousel) }
                    onEsc={() =>  setPhotoCarousel(!photoCarousel) }
                    >
                      <Button alignSelf="end" icon={<Close />} onClick={ () => setPhotoCarousel(!photoCarousel) } />
                      <SwipeCarousel 
                        autoFocus={true} 
                        autoPlay={size==="small" ? false : true} 
                        dynamicHeight={true} 
                        emulateTouch={true}
                        infiniteLoop={true}
                        showArrows={size==="small" ? false : true} 
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                        stopOnHover={false}
                        swipeable={true}
                        useKeyboardArrows={true}
                        width="100%">
                        { largeImagesList.length && largeImagesList.map( (img,i)=>{
                          return <Image  key={i} src={img} width="100%" height="auto" fit="contain" />
                          } )
                        }
                      {/* {item && item.assets && item.assets.map((img,i) => {
                        
                          if (img.type === 'IMAGE') {
                            return ( 
<Image  key={i} src={img.src} width="100%" height="auto" fit="contain" />
                                    )
                          } else {
                            return null
                          }
                          })} */}
                      </SwipeCarousel>
                    </Layer>
                  )}

                  {askCookieInfo && (
                  <Layer
                    responsive={false}
                    position="bottom"
                    modal={false}
                    margin={{ vertical: 'medium', horizontal: 'small' }}
                  >
                    <Box
                      width="medium"
                      align="center"
                      direction="row"
                      gap="small"
                      justify="between"
                      elevation="high"
                      pad={{ vertical: 'xsmall', horizontal: 'small' }}
                      background="status-ok"
                    >
                      <Box align="center" direction="row" gap="xsmall">
                        <Text color="white">
                  We use essential cookies for the functionality.
                        </Text>
                        <Button icon={<StatusGood color="white"/>} onClick={ () => setAskCookieInfo(!askCookieInfo) } />
                      </Box>
                    </Box>
                  </Layer>
                  )}   

          <Button
              icon={ <CircleInformation color='plain' size='large' /> }
              hoverIndicator = {false}
              label=""
              onClick={ () => setInfoBox(!infoBox) } 
              gap="xxsmall"
              />

                {infoBox && (
                  <Layer
                    responsive={false}
                    position="end"
                    onClickOutside={() => { setInfoBox(!infoBox)} }
                    onEsc={() => { setInfoBox(!infoBox)} }
                    >
                    <Box
                      round="medium"
                      elevation="medium"
                      pad={{ vertical: 'xsmall', horizontal: 'xsmall' }}
                      background="light-1"
                      overflow="auto"
                      >
                      <Button alignSelf="end" icon={<Close />} onClick={ () => setInfoBox(!infoBox) } />
                      
                      <Text>
                      {projectDetails.infoText}
                      </Text>

                      <Grid
                      columns={size !== 'small' ? 'small' : '100%'}
                        gap="small"
                        >
                              {projectDetails && projectDetails.assets && projectDetails.assets.map((img,i) => {
                                if (img.type === 'IMAGE') {
                                  return ( 
                                      <Image key={i} src={img.src} width="100%" height="100pt" fit="contain" />
                                    )
                                  }
                                })
                              }
                        </Grid>

                      
                        
                      </Box>
                  </Layer>
                  )}



          </Box>


            <Box 
              background="plain" 
              width={size==="small" ? "100%" : "xlarge"}
              pad={size==="small" ? "xxsmall" : "large"}
              >
                <div style={ { flex:1 } } 
                dangerouslySetInnerHTML={{ __html: sanitizer( item.description ) }}
                >
                
                </div>
                
            </Box>
          
        </Box>

        <Box pad="xxsmall" onClick={ () => setPhotoCarousel(!photoCarousel)} >

        <Masonry columnsCount={size==="small" ? 2 : 3} gutter="10px">
        {item && mediumImagesList.length && mediumImagesList.map( (img,i) => {
                    return ( 
                    <img
                        key={i}
                        src={img}
                        style={{width: "100%", display: "block"}}
                    />
                    )
                } )
              }
            </Masonry>


      </Box >


      
      
      </Box>




            {/* <Box>
              <Video controls="over" fit="cover">
                <source key="video" src="https://archive.org/details/emeraldstorybook_2106_librivox/emeraldstorybook_03_various_128kb.mp3" type="audio/mp3" />
              </Video>
            </Box> */}
            {/* <Box>
               <Image src={assets ? assets.photo.src : " "} fit="contain" /> 
            </Box> */}

<Footer justify='between' >
          <Box 
          fill="horizontal"
          background="light-3"
          onClick={ () => history.push("/") }
          align="center"
          >
              <Text weight={100} size="xsmall">Imprint ♩ Terms ♩ Copyright</Text>
          </Box>
        </Footer>


      </Box>
    )}
    </ResponsiveContext.Consumer>
        
  </Grommet>
  );
}

export default Item;
