import configureStore, { history } from '../../state/store/configureStore';

/* Configuration */
import StateLoader from "../../state/stateLoader"
import getEnvironment from '../../utils/getEnvironment';

let hostName = window ? window.location.hostname : null

const stateLoader = new StateLoader(hostName);
const initialState = stateLoader.loadState()
console.log('Loaded initial state', initialState);
delete initialState.router
delete initialState.activeOrders
const envConfig = getEnvironment(hostName)

if(!initialState.appState) initialState.appState = {}
initialState.appState.hostname = envConfig.hostname
initialState.appState.env = envConfig.env
initialState.appState.apiHost = envConfig.apiHost

/*------------------ */
const store = configureStore(initialState);

export default store