import React, { useRef } from 'react';
import { useEffect, useState } from 'react'
import { useParams, useHistory, Link } from "react-router-dom";

import {
	Box,
	Button,
	Card,
	CardBody,
	CardFooter,  
	Heading,
	Grommet,
	Grid,
	Image,  
	InfiniteScroll,
	Layer,
	Spinner,
	Table,
	TableRow,
	TableBody,
	TableCell,
	TableHeader,
	Text,  
	TextArea,
	TextInput,
  } from 'grommet';
import { CloudUpload, CirclePlay, Close, Copy, DocumentPdf, Edit, Location, NewWindow, Pause, Trash, Upload, View, Sync } from 'grommet-icons';

import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';


import UppyNoButtonImage from "../../../components/uppyImageProject";
import UppyNoButtonAudio from "../../../components/uppyAudioProject";

import ReactHowler from 'react-howler';

  
import useAPI from "../../../helpers/useAPI"
import qrcodeGenerator from "../../../helpers/qrcodeGenerator";
import QRgeneratebutton from "../../../helpers/QRgeneratebutton";
import QRcomponent from "../../../helpers/QRcomponent";

import jsPDF from 'jspdf';


// import { resetWarningCache } from 'prop-types';
// import { stripBasename } from 'history/PathUtils';

function ProjectAdminHome() {
	const history = useHistory();
	const { projectId } = useParams();
	const [ project,setProject ] = useState(null);
	const [ projectDetails,setProjectDetails ] = useState(null);
	const [ isUpdating,setUpdating ] = useState(false);
    const [ items,setItems ] = useState(null);
	const [ itemURL, setItemURL ] = useState();
	const [uppyImageState, setUppyImageState] = useState(false);
    const [uppyAudioState, setUppyAudioState] = useState(false);
    const [ playingAudio, setPlayingAudio ] = useState(false);
    const [ audioAvailable, setAudioAvailable ] = useState(false);
	const [ copiedInfo, setCopiedInfo ] = useState(false);

	const [ deleteItem, setDeleteItem ] = useState(false);

	const [ showQRLayer, setShowQRLayer ] = useState("");
	const qrcomponentRef = useRef();


	
	const widthQRcode = 540;
	const heightQRcode = 856;
	

	const newItem = async (project)=>{
		setUpdating(true)
		const res = await useAPI(`/api/projects/${project._id}/items`, 'PUT')
		const response = await res.json()
		if (  response ) {
			setUpdating(false)
		}
		fetchData()
	}


  useEffect(  ()=>{ 
    /*will run once in the beginning*/
	fetchProject()
    // let responseArray = null
	// responseArray = useAPI(`/api/items`)
	// console.log(responseArray[0])
	// setItems( responseArray[0] ) 
  } , [ ] )

  async function fetchProject() {

	const res = await useAPI(`/api/projects/${projectId}`)
	const response = await res.json()
	setProject( response.data )

	fetchDetails()
  }

  async function fetchDetails() {

	const res = await useAPI(`/api/projects/${projectId}/details`)
	const response = await res.json()
	
	setProjectDetails( response.data )

	fetchData()
  }

  async function fetchData() {
	// console.log('fetching items')
    // const res = await fetch(`/api/items`)
	const res = await useAPI(`/api/projects/${projectId}/items`)
	const response = await res.json()

	setItems( response.data )
    
    }

  const updateDB = async (project, details)=>{
	setUpdating(true)
	const res1 = await useAPI(`/api/projects/${project._id}`, 'POST', JSON.stringify(project))
	const response1 = await res1.json()

	const res2 = await useAPI(`/api/projects/${project._id}/details`, 'POST', JSON.stringify(details))
	const response2 = await res2.json()
		
	if (  response1 && response2 ) {
		  setUpdating(false)
	  }
	}
	


	const theme = {
		global: {
		  colors: {
			brand: '#228BE6',
			},
		  font: {
			family: 'Roboto',
			size: '18px',
			height: '20px',
		  },
		},
	  };
	
	const step = 25;

	const changeField = (field, value) =>{
		const newProj = Object.assign({}, project)
		newProj[field] = value
		setProject(newProj)
	}

	const changeDetailField = (field, value) =>{
		const newProj = Object.assign({}, projectDetails)
		newProj[field] = value
		setProjectDetails(newProj)
	}

	const changeLongitude = (value) =>{
		const newProj = Object.assign({}, projectDetails)
			newProj["geolocation"] = {"type": "Point", "coordinates": [value, projectDetails.geolocation.coordinates[1]] }
			setProjectDetails(newProj)
		}
	
	  const changeLatitude = (value) =>{
		const newProj = Object.assign({}, projectDetails)
			newProj["geolocation"] = {"type": "Point", "coordinates": [projectDetails.geolocation.coordinates[0], value] }
			setProjectDetails(newProj)
		}
	

	const deleteAsset = async (projectId, assetId) =>{
		const res = await useAPI(`/api/projects/${projectId}/details/assets/${assetId}`, 'DELETE')
		const response = await res.json()
		fetchProject()
	}
	 
	const removeItem = async (itemId) =>{
		const res = await useAPI(`/api/projects/${projectId}/items/${itemId}`, 'DELETE')
		const response = await res.json()
		if(res.ok){
			setDeleteItem(!deleteItem)
			fetchProject()
		}
	}



	if ( !project || !projectDetails ) return (<div><Spinner /></div>)
	if ( !items) return (<div><Spinner /></div>)
  return (
	<Grommet theme={theme} full>
	<Box
        tag='header'
        direction='row'
        align='center'
        justify='between'
        background='brand'
        pad={{ left: 'medium', right: 'small', vertical: 'small' }}
        elevation='medium'
        style={{ zIndex: '1' }}
        >
          <Heading level='3' margin='none'>{project.name}</Heading>
		 
    </Box>

	<Box
        background='dark-2'
        align='center'
        justify='between'
        pad="small"
        >
          <Heading level='4' margin='none'>{"http://www.kunstlink.com"+`/p/${project.shortName}`}</Heading>
    </Box>


	<Box
	  direction="column"
	>
		<Box 
			direction="row" 
			align="center"
			background="light-3"
			pad = "large"
		>


		<Box width="large" pad='medium' direction='column'>


			<Box fill align="start" pad="small" >
			<Text>Project name</Text>
			<TextInput 
			size="medium"
			onChange={(e) => changeField('name', e.target.value)} 
			value={project.name}
			/>
			</Box>

			<Box fill align="start" pad="small" height="medium" >
			<Text>Color</Text>
			<TextInput 
			value={projectDetails.brandColor}
			onChange={(e) => changeDetailField('brandColor', e.target.value)} 
			/>
			</Box>

			<Box fill align="start" pad="small" >
			<Text>Project center coordinates in the map, longitude and latitude</Text>
			<TextInput 
			size="medium"
			icon={<Location />}
			onChange={(e) => changeLongitude(e.target.value)} 
			value={projectDetails.geolocation.coordinates[0]}
			/>
			<TextInput 
			size="medium"
			icon={<Location />}
			onChange={(e) => changeLatitude(e.target.value)} 
			value={projectDetails.geolocation.coordinates[1]}
			/>
			</Box>

			

			<Box align="start" pad="small" width ="large" height="medium" >
			<Text>Info</Text>
			<TextArea 
			fill
			value={projectDetails.infoText}
			onChange={(e) => changeDetailField('infoText', e.target.value)} 
			/>
			</Box>

		</Box>

		<Button
		icon={<Sync color="black" />}
		hoverIndicator
		primary
		active
		disabled={isUpdating}
		label="Update project details"
		onClick={()=> updateDB(project, projectDetails) }
		gap="large"
		alignSelf="center"
		align="center"
		/>

		</Box>



		<Box fill align="start" pad="medium" height="medium" >

        <Text>Info audio</Text>

        {projectDetails && projectDetails.assets && projectDetails.assets.map((audio) => {
          if (audio.type === 'AUDIO') {
            return ( 

              <Box
              key = {audio._id}
              background="plain" 
              width="small"
              pad="small" 
			  direction='row'
              >
  
              <ReactHowler
                  src = {audio.src}
                  playing = {playingAudio}
                  />
              <Button
                icon={ playingAudio ? <Pause color='plain' size='large' /> : <CirclePlay color='plain' size='large' /> }
                hoverIndicator = {false}
                label=""
                onClick={ () => { 
                  setPlayingAudio(!playingAudio)
                }} 
                gap="xxsmall"
                />

              <Button
                primary
                icon={<Trash color="white" />}
                tip={{ dropProps: { align: { left: 'right' } }, content: 'delete this audio' }}
                hoverIndicator
                label="Delete"
                onClick={()=> deleteAsset(project._id, audio._id) }
                gap="medium"
                alignSelf="start"
              />

            </Box>
  
                    )
          }
          })}

        <Box pad="small">
        <Button
              icon={<Upload color='white'/>}
              primary
              disabled={audioAvailable}
              hoverIndicator
              label="Upload audio"
              onClick={()=>{ setUppyAudioState(true)}}
              gap="xxsmall"
              alignSelf="start"
            />

        </Box>
        <UppyNoButtonAudio projectId={projectId} callback={fetchProject} isOpen={uppyAudioState} setOpen={(a)=>{setUppyAudioState(a)}}/>
      </Box>


      <Box fill align="start" pad="medium" height="medium" >
        <Text>Sponsor logos</Text>
        <Grid
          columns={{
            count: 3,
            size: 'auto',
          }}
          gap="small"
          >
                {projectDetails && projectDetails.assets && projectDetails.assets.map((img) => {
                  if (img.type === 'IMAGE') {
                    return ( <Card key={img._id} pad="xsmall" background="light-3" width="small">
                              <CardBody><Image  src={img.src} /></CardBody>
                              <CardFooter>    
                                <Box pad="small">
                                <Button
                                  primary
                                  icon={<Trash color="white" />}
                                  tip={{ dropProps: { align: { left: 'right' } }, content: 'delete this picture' }}
                                  hoverIndicator
                                  label="Delete"
                                  onClick={()=> deleteAsset(project._id, img._id)}
                                  gap="medium"
                                  alignSelf="start"
                                />
                                </Box>
                              </CardFooter>
                              </Card>
                            )
                    }
                  })
                }
          </Grid>

        <Box pad="small">
        <Button
              icon={<CloudUpload color="white" />}
              primary
              hoverIndicator
              label="Upload image"
              onClick={()=>{ setUppyImageState(true)}}
              gap="xxsmall"
              alignSelf="start"
            />
        </Box>
        <UppyNoButtonImage projectId={projectId} callback={fetchProject} isOpen={uppyImageState} setOpen={(a)=>{setUppyImageState(a)}}/>
      </Box>

	
	</Box>


	<Box pad='medium' direction='row-reverse' background="light-2">

		<Button
		icon={<NewWindow color="black" />}
		hoverIndicator
		primary
		active
		disabled={isUpdating}
		label="New item"
		onClick={()=> newItem(project) }
		gap="medium"
		alignSelf="start"
		/>
	</Box>

	<Box background="light-2">

	<Table>
	<TableHeader>
		<TableRow key="header">
			<TableCell>Title</TableCell>
			<TableCell>Link</TableCell>
			<TableCell>Latitude</TableCell>
			<TableCell>Longitude</TableCell>
			<TableCell>QR code</TableCell>
			<TableCell>Edit</TableCell>
			<TableCell>Delete</TableCell>
		</TableRow>
	</TableHeader>
	<TableBody>
			{items.map(loc => 	
				(
				<TableRow key={loc._id}>
					<TableCell>{loc.title}</TableCell>
					<TableCell> 
							<Button
								icon={<Copy color="black" />}
								hoverIndicator
								label="copy"
								onClick={()=>  {
									navigator.clipboard.writeText("http://kunstlink.com"+`/item/${loc._id}`);
									setCopiedInfo(true);
									setTimeout(() => {
										setCopiedInfo(false);
									}, 2000);
									} } 
								/>
								    {copiedInfo && (
										<Layer
											responsive={false}
											position="bottom"
											modal={false}
											margin={{ vertical: 'medium', horizontal: 'small' }}
										>
											<Box
											animation="pulse"
											align="center"
											direction="row"
											gap="small"
											justify="between"
											pad={{ vertical: 'xsmall', horizontal: 'small' }}
											background="dark-2"
											>
											<Box align="center" direction="row" gap="xsmall">
												<Text color="white">
										Link is copied to your clipboard.
												</Text>
											</Box>
											</Box>
										</Layer>
										)}  
					</TableCell>
					<TableCell>{loc.geolocation.coordinates[0]}</TableCell>
					<TableCell>{loc.geolocation.coordinates[1]}</TableCell>
					<TableCell>							
							<Button
									hoverIndicator
									icon={<View /> }
									onClick={() => setShowQRLayer(loc._id)}
              					/>
							
 					</TableCell>
					<TableCell><Button
								icon={<Edit />}
								hoverIndicator
								onClick={() => {history.push(`/item/${loc._id}/edit`)}  }
							/>
					</TableCell>
					<TableCell><Button
								icon={<Trash />}
								hoverIndicator
								onClick={() => setDeleteItem(!deleteItem) }
							/>

							{deleteItem && (
							<Layer 
							animation="fadeIn"
							responsive={false}
							position="center"
							onClickOutside={() => { setDeleteItem(!deleteItem) } }
							onEsc={() => { setDeleteItem(!deleteItem) } }
							>
							<Box
							  round="medium"
							  elevation="medium"
							  pad='small'
							  gap='small'
							  background="light-3"
							  >
								  <Text>Deleting an item cannot be undone!</Text>
								  <Box direction="row" justify='between'>
											<Button
											primary
											active
											disabled={false} // Deleting is not working, so we disable for the moment
											icon={<Trash color="black"/>}
											label="Delete"
											hoverIndicator
											onClick={() => removeItem(loc._id)  }
										/>
										<Button
											primary
											active
											icon={<Close color="black"/>}
											label="cancel"
											hoverIndicator
											onClick={() =>  setDeleteItem(!deleteItem) }
										/>
								</Box>
							</Box>
							</Layer>
							)}
					</TableCell>
				</TableRow>
				))}
	</TableBody>
	</Table>
	</Box>
	{showQRLayer && (
		<Layer 
		animation="fadeIn"
		responsive={false}
		position="center"
		onClickOutside={() => { setShowQRLayer("") } }
		onEsc={() => { setShowQRLayer("") } }
		>
		<Box
			round="medium"
			elevation="medium"
			pad='small'
			gap='small'
			background="light-3"
			>
				<Box direction="row" justify='between'>
			<Button
			alignSelf="start"
			primary
				label="Save"
				icon={<DocumentPdf /> }
				onClick={() => exportComponentAsPDF(qrcomponentRef, {fileName: `QR_${showQRLayer}.pdf`, pdfOptions: {h:heightQRcode, w:widthQRcode, unit: 'pt', pdfFormat: [heightQRcode,widthQRcode]} } )}
			/>
			<Button primary alignSelf="end" icon={<Close />} onClick={ () => { setShowQRLayer("") } } />
			</Box>
		<QRcomponent hidden={true} link={showQRLayer} width={widthQRcode} height={heightQRcode} ref={qrcomponentRef} />
		</Box>
		</Layer>
		)}
	</Grommet>     
  );
}

export default ProjectAdminHome;