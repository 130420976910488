import React, { forwardRef, Ref, useImperativeHandle, useRef } from 'react';

import { 
  Bold, 
  Italic, 
  Underline,
  OrderedList,
  UnorderedList,
  List,
  Menu,
  Subscript,
  Superscript,
} from 'grommet-icons';

// import 'remirror/styles/all.css';
import '../editor/all.css';
// import { cx, htmlToProsemirrorNode } from 'remirror';

import { 
  BoldExtension, 
  ItalicExtension, 
  UnderlineExtension, 
  SearchExtension,
  BulletListExtension,
  HardBreakExtension,
  HeadingExtension,
  LinkExtension,
  OrderedListExtension,
  SubExtension,
  SupExtension
} from 'remirror/extensions';

import { 
  Remirror, 
  ThemeProvider, 
  useRemirror, 
  OnChangeHTML, 
} from '@remirror/react';

import 'remirror/styles/all.css'; 

import { 
  Button,
  Box,
  Text,
} from 'grommet';

// import { AllStyledComponent } from '@remirror/styles/styled-components';


const extensions = () => [
    new BoldExtension(),
    new ItalicExtension(),
    new UnderlineExtension(),
    new SearchExtension(),
    new BulletListExtension(),
    new OrderedListExtension(),
    new HeadingExtension(),
    new LinkExtension(),
    new HardBreakExtension(),
    new SubExtension(),
    new SupExtension(),
];

const Editor = forwardRef(( {contentDB, updateHandler}, ref ) => {
  const editorRef = useRef(null);
  const { manager, state, getContext } = useRemirror({
    builtin: { persistentSelectionClass: 'selection' },
    extensions: extensions,
    content: contentDB,
    stringHandler: 'html',
  });


  useImperativeHandle(editorRef, () => getContext(), [getContext]);



  return (
    <Box>
      <Box width="large" pad="xsmall" gap="medium" direction='row-responsive'>
        <Button
          icon={<Bold color="black" />}
          hoverIndicator
          primary
          active
          label="Bold"
          onClick={() => editorRef.current.commands.toggleBold() }
          gap="small"
		      alignSelf="center"
		      align="center"
		    />
        <Button
          icon={<Italic color="black" />}
          hoverIndicator
          primary
          active
          label="Italic"
          onClick={() => editorRef.current.commands.toggleItalic() }
          gap="small"
		      alignSelf="center"
		      align="center"
		    />
        <Button
          icon={<Underline color="black" />}
          hoverIndicator
          primary
          active
          label="Underline"
          onClick={() => editorRef.current.commands.toggleUnderline() }
          gap="small"
		      alignSelf="center"
		      align="center"
		    />
      </Box>

      <Box width="large" pad="xsmall" gap="medium" direction='row-responsive'>
        <Button
          icon={<Superscript color="black" />}
          hoverIndicator
          primary
          active
          label="Superscript"
          onClick={() => editorRef.current.commands.toggleSuperscript() }
          gap="small"
		      alignSelf="center"
		      align="center"
		    />
        <Button
          icon={<Subscript color="black" />}
          hoverIndicator
          primary
          active
          label="Subscript"
          onClick={() => editorRef.current.commands.toggleSubscript() }
          gap="small"
		      alignSelf="center"
		      align="center"
		    />
      </Box>

      <Box width="large" pad="xsmall" gap="medium" direction='row-responsive'>
        <Button
          icon={<OrderedList color="black" />}
          hoverIndicator
          primary
          active
          label="Enumerate"
          onClick={() => editorRef.current.commands.toggleOrderedList() }
          gap="small"
		      alignSelf="center"
		      align="center"
		    />
        <Button
          icon={<List color="black" />}
          hoverIndicator
          primary
          active
          label="Itemize"
          onClick={() => editorRef.current.commands.toggleBulletList() }
          gap="small"
		      alignSelf="center"
		      align="center"
		    />
        <Button
          icon={<Menu color="black" />}
          hoverIndicator
          primary
          active
          label="Unlist"
          onClick={() => editorRef.current.commands.liftListItemOutOfList() }
          gap="small"
		      alignSelf="center"
		      align="center"
		    />
        {/* <Button
          icon={<DocumentText color="black" />}
          hoverIndicator
          primary
          active
          label="Normal"
          onClick={() => editorRef.current.commands.setFontSize(18) }
          gap="small"
		      alignSelf="center"
		      align="center"
		    />
        <Button
          icon={<DocumentTxt color="black" />}
          hoverIndicator
          primary
          active
          label="Large"
          onClick={() => editorRef.current.commands.setFontSize(24) }
          gap="small"
		      alignSelf="center"
		      align="center"
		    /> */}
      </Box>
    
        {/* <button
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => editorRef.current.commands.toggleBold() }
        >
          <Bold />
        </button>
        <button
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => editorRef.current.commands.toggleItalic() }
        >
          <Italic />
        </button> */}
      
      
      
      

        <Box border="true" fill="horizontal"  height="large" overflow={{ vertical: "scroll", horizontal: "hidden" }}>
        <ThemeProvider >
          {/* <AllStyledComponent> */}
        
          <Remirror 
            manager={manager} 
            initialContent={state} 
            autoRender='end'
            >
            <OnChangeHTML 
              onChange={ (data) => { updateHandler( data ) }  } 
            />
          </Remirror>
          
          {/* </AllStyledComponent> */}
          </ThemeProvider>
          </Box> 
          
      
      


      {/* const amountWords = editorRef.current!.commands.getWordCount(); */}
  {/* const amountCharacter = editorRef.current!.commands.getCharacterCount(); */}

        {/* <Text>{ () => editorRef.current.commands.getWordCount() } words and  characters</Text> */}

        
    </Box>
  );
});

export default Editor;



// const extensions = () => [
//     new BoldExtension(),
//     new ItalicExtension()
// ];



// const Buttons = () => {
//   const commands = useCommands();
//   const active = useActive(true);
//   return (
//     <div>
//         <button
//         onMouseDown={(event) => event.preventDefault()}
//         onClick={() => commands.toggleBold()}
//         className={cx(active.bold() && 'active')}
//         >
//         <Bold />
//         </button>
//         <button
//         onMouseDown={(event) => event.preventDefault()}
//         onClick={() => commands.toggleItalic()}
//         className={cx(active.italic() && 'active')}
//         >
//         <Italic />
//         </button>
//     </div>
//   );
// };


// const Editor = ( {contentDB, updateHandler } ) => {
//   const { manager, state } = useRemirror({
//     extensions: extensions,
//     content: contentDB,
//     stringHandler: htmlToProsemirrorNode,
//   });

  
//   return (
    
//     <ThemeProvider style={{ padding: 16 , height: 500}}>
//       <div style={{ height: 200, backgroundColor:"red" }}>
//       <Bold />
//       </div>
//       <div style={{height: 500}}>
//       <Remirror
//         manager={manager}
//         autoFocus
//         initialContent={state}
//         autoRender="start"
//       >
//         <Buttons />
//         <OnChangeHTML onChange={ (data) => { updateHandler( data ) }  } />
        
//       </Remirror>
//       </div>

//     </ThemeProvider>
//   );
// };

// export default Editor;










// const extensions = () => [new BoldExtension()];

// const Editor = forwardRef(( {contentDB, updateHandler}, ref ) => {
//   const { manager, state, setState, getContext } = useRemirror({
//     extensions: extensions,
//     content: contentDB,
//     stringHandler: htmlToProsemirrorNode,
//   }
// );

//   useImperativeHandle(ref, () => getContext(), [getContext]);

//   // Add the state and create an `onChange` handler for the state.
//   return (
//     <Remirror
//       manager={manager}
//       state={state}
//       onChange={(parameter) => {
//         // Update the state to the latest value.
//         setState(parameter.state);
//         updateHandler( parameters );
//       }}
//     />
//   );
// });

// export default Editor;


















// import React from 'react';

// import { WysiwygEditor } from '@remirror/react-editors/wysiwyg';

// const Editor = () => {
//   return (
//     <div style={{ padding: 16 }}>
//       <WysiwygEditor placeholder='Enter text...' />
//     </div>
//   );
// };

// export default Editor;